var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("layout", [
    _c(
      "div",
      {
        staticClass: "section phone-monitor",
        attrs: { slot: "content" },
        slot: "content",
      },
      [
        _c(
          "el-dialog",
          {
            attrs: {
              title: "手机设备检测",
              visible: _vm.showPushTest,
              width: "400px",
            },
            on: {
              "update:visible": function ($event) {
                _vm.showPushTest = $event
              },
            },
          },
          [
            _c("div", { staticClass: "content" }, [
              _c("i", { staticClass: "iconfont icontishi" }),
              _c("span", [
                _c("span", { staticClass: "title" }, [
                  _vm._v("手机设备视频监控检测中…"),
                ]),
                _c("br"),
                _vm._v(" 大约需要10-20秒，请稍等！"),
              ]),
            ]),
            _c("div", { staticClass: "loading-container" }, [
              _c("div", { staticClass: "loading" }),
              _c("div", { attrs: { id: "loading-text" } }, [_vm._v("进行中")]),
            ]),
          ]
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "手机监控检测结果",
              "show-close": false,
              visible: _vm.showPushSuccess,
              width: "450px",
            },
            on: {
              "update:visible": function ($event) {
                _vm.showPushSuccess = $event
              },
            },
          },
          [
            _c("div", { staticClass: "content" }, [
              _c("i", { staticClass: "iconfont iconwancheng" }),
              _c("span", [
                _c("span", { staticClass: "title" }, [
                  _c("span", { staticStyle: { color: "#717171" } }, [
                    _vm._v("您的手机设备检测："),
                  ]),
                  _c("span", { staticStyle: { "font-size": "24px" } }, [
                    _vm._v("通过"),
                  ]),
                ]),
                _c("br"),
                _vm._v(
                  "考试全程请务必开启手机视频监控，并按图示放置合适位置，可以看到上半身和电脑全景，监控画面违规会影响到考试成绩。 "
                ),
              ]),
            ]),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.scan()
                      },
                    },
                  },
                  [_vm._v("下一步")]
                ),
              ],
              1
            ),
          ]
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "手机监控规范摆放示意图",
              center: true,
              "show-close": false,
              visible: _vm.showPhonePosition,
              width: "700px",
            },
            on: {
              "update:visible": function ($event) {
                _vm.showPhonePosition = $event
              },
            },
          },
          [
            _c("div", { staticClass: "content" }, [
              _c("img", {
                attrs: {
                  src: require("../../../assets/images/phonePosition.png"),
                  alt: "",
                },
              }),
            ]),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.pushTestClick()
                      },
                    },
                  },
                  [_vm._v("我知道了")]
                ),
              ],
              1
            ),
          ]
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "手机监控检测结果",
              visible: _vm.showPushFail,
              width: "450px",
            },
            on: {
              "update:visible": function ($event) {
                _vm.showPushFail = $event
              },
            },
          },
          [
            _c("div", { staticClass: "content" }, [
              _c("i", { staticClass: "iconfont iconguanbi-3" }),
              _c("span", [
                _c("span", { staticClass: "title" }, [
                  _c("span", { staticStyle: { color: "#717171" } }, [
                    _vm._v("您的手机设备检测："),
                  ]),
                  _c("span", { staticStyle: { "font-size": "24px" } }, [
                    _vm._v("不通过"),
                  ]),
                ]),
                _c("br"),
                _vm._v(
                  "考试要求开启手机视频监控，请查看下方解决方案，不开启监控会影响考试成绩。"
                ),
              ]),
            ]),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: () => {
                        _vm.showSolution = true
                        _vm.showPushFail = false
                      },
                    },
                  },
                  [_vm._v("查看解决方案")]
                ),
              ],
              1
            ),
          ]
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "解决方案",
              visible: _vm.showSolution,
              width: "450px",
            },
            on: {
              "update:visible": function ($event) {
                _vm.showSolution = $event
              },
            },
          },
          [
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "container" }, [
                _c("i", { staticClass: "iconfont iconkey" }),
                _c("div", [
                  _c("span", { staticClass: "title" }, [
                    _vm._v("请依次尝试： "),
                  ]),
                  _c("br"),
                  _vm._v(" 1、刷新二维码，用微信重新扫码； "),
                  _c("br"),
                  _vm._v("2、更换4G上网或其他较快的网络； "),
                  _c("br"),
                  _vm._v("3、重启手机； "),
                  _c("br"),
                  _vm._v("4、更换其他手机尝试； "),
                  _c("br"),
                  _vm._v("5、使用iPad登录微信进行扫码； "),
                  _c("br"),
                  _vm._v("6、升级手机微信app至最新版。 "),
                  _c("br"),
                ]),
              ]),
            ]),
            _c(
              "div",
              {
                staticStyle: {
                  display: "block",
                  "margin-top": "20px",
                  "font-weight": "bold",
                },
              },
              [
                _vm._v(
                  " 如果您的手机设备检测不通过，请按照以上方法解决，如果仍有问题，请点击网页右侧客服图标留言。 "
                ),
              ]
            ),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.showSolution = false
                      },
                    },
                  },
                  [_vm._v("我知道了")]
                ),
              ],
              1
            ),
          ]
        ),
        _c("div", { staticClass: "title" }, [_vm._v("微信扫码，开启手机监控")]),
        _c("div", { staticClass: "tips" }, [
          _vm._v(
            " * 请用手机微信扫码，并保持考试全程手机屏幕处于常亮状态，关闭自动锁屏 "
          ),
        ]),
        _c("div", { staticClass: "main" }, [
          _c("div", { staticClass: "step" }, [
            _vm.mobileMonitor
              ? _c("span", { staticClass: "step-title" }, [
                  _vm._v("1. 手机微信扫码"),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "img-container qr-container" },
              [
                _c("vue-qr", {
                  staticStyle: { width: "160px", height: "160px" },
                  attrs: { size: 800, text: _vm.pagePath },
                }),
                _vm.isFailure
                  ? _c(
                      "div",
                      { staticClass: "refresh-qr" },
                      [
                        _c("span", { staticClass: "failure" }, [
                          _vm._v("二维码已失效"),
                        ]),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "danger" },
                            on: { click: _vm.refresh },
                          },
                          [_vm._v("请点击刷新")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "span",
              { staticClass: "detail" },
              [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: { effect: "dark", placement: "bottom" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: { "line-height": "1.5" },
                        attrs: { slot: "content" },
                        slot: "content",
                      },
                      [
                        _vm._v(" 扫码遇到问题怎么办？"),
                        _c("br"),
                        _vm._v("1、请检查你的手机网络连接是否正常；"),
                        _c("br"),
                        _vm._v("2、请更新手机微信APP至最新版，然后重新扫码；"),
                        _c("br"),
                        _vm._v(
                          "3、以上方法均不能解决，请点击右侧“客服”小窗联系我们；"
                        ),
                        _c("br"),
                        _vm._v(
                          " 4、手机在考试中需一直打开小程序，不要跳出监控页面，建议插上手机电源。 "
                        ),
                      ]
                    ),
                    _c("i", { staticClass: "iconfont iconwenhao" }),
                  ]
                ),
                _vm._v(" 扫码遇故障?"),
              ],
              1
            ),
          ]),
          _vm.mobileMonitor
            ? _c(
                "div",
                { staticClass: "step", staticStyle: { margin: "0 50px" } },
                [
                  _c("span", { staticClass: "step-title" }, [
                    _vm._v("2. 竖立摆放手机"),
                  ]),
                  _c("div", { staticClass: "img-container" }, [
                    _c("img", {
                      attrs: {
                        width: "160px",
                        src: require("../../../assets/images/phonePos.jpg"),
                        alt: "",
                      },
                    }),
                  ]),
                  _c("span", { staticClass: "detail" }, [
                    _vm._v("电脑正侧方放置手机"),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.mobileMonitor
            ? _c("div", { staticClass: "step" }, [
                _c("span", { staticClass: "step-title" }, [
                  _vm._v("3. 开启视频监控"),
                ]),
                _c("div", { staticClass: "img-container" }, [
                  _c("img", {
                    staticStyle: { border: "1px solid #c1c1c1" },
                    attrs: {
                      height: "200px",
                      src: require("../../../assets/images/startRecord.jpg"),
                      alt: "",
                    },
                  }),
                ]),
                _c("span", { staticClass: "detail" }, [
                  _vm.$store.state.mobileDetect
                    ? _c("div", [
                        _vm._v(" 手机录制:"),
                        _c("span", { staticStyle: { color: "#cb2a1d" } }, [
                          _vm._v("已开启"),
                        ]),
                      ])
                    : _c("span", [_vm._v("确认是否开始录制")]),
                ]),
              ])
            : _vm._e(),
          _vm.mobileVideo
            ? _c("span", { staticStyle: { width: "20px" } })
            : _vm._e(),
          _vm.mobileVideo
            ? _c("div", { staticClass: "mobile-status" }, [
                _c("span", { staticStyle: { "font-size": "14px" } }, [
                  _vm._v("手机监控："),
                ]),
                _vm.$store.state.mobileDetect
                  ? _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v(" 已开启"),
                    ])
                  : _c(
                      "span",
                      {
                        staticStyle: { color: "#f35a5a", "font-size": "20px" },
                      },
                      [_vm._v("未开启")]
                    ),
                _c(
                  "div",
                  {
                    staticStyle: { "font-size": "12px", "margin-top": "20px" },
                  },
                  [_vm._v(" 手机监控状态有15s延迟 ")]
                ),
              ])
            : _vm._e(),
        ]),
        _c(
          "div",
          { staticClass: "operate" },
          [
            _c("span", { staticClass: "prev", on: { click: _vm.prev } }, [
              _vm._v("上一步"),
            ]),
            _vm.mobileVideo
              ? _c(
                  "el-button",
                  { staticClass: "scan", on: { click: _vm.next } },
                  [_vm._v(" 我已扫码，下一步 ")]
                )
              : _c(
                  "el-button",
                  {
                    staticClass: "scan",
                    on: {
                      click: function ($event) {
                        _vm.showPhonePosition = true
                      },
                    },
                  },
                  [_vm._v(" 我已扫码，开始检测手机 ")]
                ),
            _vm.$store.state.examInfo.isSkipCapture !== false
              ? _c("span", { staticClass: "next" }, [
                  _c("span", { on: { click: _vm.next } }, [
                    _vm._v("跳过，下一步"),
                  ]),
                ])
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }