<!--
 * @Author: Libra
 * @Date: 2021-05-18 11:35:18
 * @LastEditTime: 2024-05-06 15:30:26
 * @LastEditors: Libra
 * @Description: 手机监控
 * @FilePath: /stone-exam-ui/src/views/confirm/mobile/index.vue
-->
<template>
  <layout>
    <div slot="content" class="section phone-monitor">
      <el-dialog
        title="手机设备检测"
        :visible.sync="showPushTest"
        width="400px"
      >
        <div class="content">
          <i class="iconfont icontishi" />
          <span
            ><span class="title">手机设备视频监控检测中…</span><br />
            大约需要10-20秒，请稍等！</span
          >
        </div>
        <div class="loading-container">
          <div class="loading"></div>
          <div id="loading-text">进行中</div>
        </div>
      </el-dialog>
      <el-dialog
        title="手机监控检测结果"
        :show-close="false"
        :visible.sync="showPushSuccess"
        width="450px"
      >
        <div class="content">
          <i class="iconfont iconwancheng" />
          <span
            ><span
class="title"
              ><span style="color: #717171;">您的手机设备检测：</span
              ><span style="font-size: 24px;">通过</span> </span
            ><br />考试全程请务必开启手机视频监控，并按图示放置合适位置，可以看到上半身和电脑全景，监控画面违规会影响到考试成绩。
          </span>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="scan()">下一步</el-button>
        </span>
      </el-dialog>
      <el-dialog
        title="手机监控规范摆放示意图"
        :center="true"
        :show-close="false"
        :visible.sync="showPhonePosition"
        width="700px"
      >
        <div class="content">
          <img src="../../../assets/images/phonePosition.png" alt="" />
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button
type="primary"
@click="pushTestClick()"
            >我知道了</el-button
          >
        </span>
      </el-dialog>
      <el-dialog
        title="手机监控检测结果"
        :visible.sync="showPushFail"
        width="450px"
      >
        <div class="content">
          <i class="iconfont iconguanbi-3" />
          <span
            ><span
class="title"
              ><span style="color: #717171;">您的手机设备检测：</span
              ><span style="font-size: 24px;">不通过</span> </span
            ><br />考试要求开启手机视频监控，请查看下方解决方案，不开启监控会影响考试成绩。</span
          >
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            @click="
              () => {
                showSolution = true;
                showPushFail = false;
              }
            "
            >查看解决方案</el-button
          >
        </span>
      </el-dialog>
      <el-dialog title="解决方案" :visible.sync="showSolution" width="450px">
        <div class="content">
          <div class="container">
            <i class="iconfont iconkey"></i>
            <div>
              <span class="title">请依次尝试： </span><br />
              1、刷新二维码，用微信重新扫码；
              <br />2、更换4G上网或其他较快的网络； <br />3、重启手机；
              <br />4、更换其他手机尝试； <br />5、使用iPad登录微信进行扫码；
              <br />6、升级手机微信app至最新版。
              <br />
            </div>
          </div>
        </div>
        <div style="display: block;margin-top: 20px; font-weight: bold">
          如果您的手机设备检测不通过，请按照以上方法解决，如果仍有问题，请点击网页右侧客服图标留言。
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button
@click="showSolution = false"
type="primary"
            >我知道了</el-button
          >
        </span>
      </el-dialog>
      <div class="title">微信扫码，开启手机监控</div>
      <div class="tips">
        * 请用手机微信扫码，并保持考试全程手机屏幕处于常亮状态，关闭自动锁屏
      </div>
      <div class="main">
        <div class="step">
          <span class="step-title" v-if="mobileMonitor">1. 手机微信扫码</span>
          <div class="img-container qr-container">
            <vue-qr
              style="width: 160px;height: 160px;"
              :size="800"
              :text="pagePath"
            ></vue-qr>
            <div class="refresh-qr" v-if="isFailure">
              <span class="failure">二维码已失效</span>
              <el-button type="danger" @click="refresh">请点击刷新</el-button>
            </div>
          </div>
          <span
class="detail"
            ><el-tooltip class="item" effect="dark" placement="bottom">
              <div slot="content" style="line-height: 1.5">
                扫码遇到问题怎么办？<br />1、请检查你的手机网络连接是否正常；<br />2、请更新手机微信APP至最新版，然后重新扫码；<br />3、以上方法均不能解决，请点击右侧“客服”小窗联系我们；<br />
                4、手机在考试中需一直打开小程序，不要跳出监控页面，建议插上手机电源。
              </div>
              <i class="iconfont iconwenhao"></i>
            </el-tooltip>
            扫码遇故障?</span
          >
        </div>
        <div class="step" style="margin: 0 50px;" v-if="mobileMonitor">
          <span class="step-title">2. 竖立摆放手机</span>
          <div class="img-container">
            <img width="160px" src="../../../assets/images/phonePos.jpg" alt="" />
          </div>
          <span class="detail">电脑正侧方放置手机</span>
        </div>
        <div class="step" v-if="mobileMonitor">
          <span class="step-title">3. 开启视频监控</span>
          <div class="img-container">
            <img
              style="border: 1px solid #c1c1c1;"
              height="200px"
              src="../../../assets/images/startRecord.jpg"
              alt=""
            />
          </div>
          <span class="detail">
            <div v-if="$store.state.mobileDetect">
              手机录制:<span style="color: #cb2a1d">已开启</span>
            </div>
            <span v-else>确认是否开始录制</span></span
          >
        </div>
        <span v-if="mobileVideo" style="width: 20px;"></span>
        <div class="mobile-status" v-if="mobileVideo">
          <span style="font-size: 14px">手机监控：</span
          ><span v-if="$store.state.mobileDetect" style="color: red;">
            已开启</span
          >
          <span v-else style="color: #f35a5a; font-size: 20px">未开启</span>
          <div style="font-size: 12px;margin-top: 20px;">
            手机监控状态有15s延迟
          </div>
        </div>
      </div>
      <div class="operate">
        <span class="prev" @click="prev">上一步</span>
        <el-button class="scan" @click="next" v-if="mobileVideo">
          我已扫码，下一步
        </el-button>
        <el-button class="scan" @click="showPhonePosition = true" v-else>
          我已扫码，开始检测手机
        </el-button>
        <span class="next" v-if="$store.state.examInfo.isSkipCapture !== false" >
          <span @click="next">跳过，下一步</span>
        </span>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from '@/views/confirm/layout'
import Api from '@/api/api'
import VueQr from 'vue-qr'

export default {
  name: 'ConfirmCamera',
  components: {
    Layout,
    VueQr
  },
  data() {
    return {
      pagePath: '',
      isFailure: false,
      showPushTest: false,
      showPushFail: false,
      showPushSuccess: false,
      showPhonePosition: false,
      showSolution: false,
      mobileMonitor: this.$store.state.examInfo.mobileMonitorType === 0,
      mobileVideo: this.$store.state.examInfo.mobileVideoType === 0,
      cameraMonitor: this.$store.state.examInfo.cameraMonitorType === 0
    }
  },
  created() {
    this.getMobileToken()
    this.refreshQR()
  },
  methods: {
    // 已扫码，下一步
    scan() {
      this.$router.push('/notice')
    },
    // 上一步
    prev() {
      const {
        isFaceRecognizeNeed,
        cameraMonitorType
      } = this.$store.state.examInfo
      const { idCardType } = this.$store.state.userInfo
      if (cameraMonitorType === 0) {
        this.$router.push('/basic')
      } else {
        if (isFaceRecognizeNeed && idCardType === 1) {
          this.$router.push('/face')
        } else {
          this.$router.push('/camera')
        }
      }
    },
    // 下一步
    next() {
      this.$router.push('/notice')
    },
    refreshQR() {
      const t = setTimeout(() => {
        this.isFailure = true
        clearTimeout(t)
      }, 60000)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(t)
      })
    },
    async refresh() {
      await this.getMobileToken()
      this.isFailure = false
      this.refreshQR()
    },
    async getMobileToken() {
      const res = await Api.getMobileToken()
      if (res.code === 0) {
        const env = process.env.NODE_ENV
        // const env = 'beta'
        const host = 'https://exam.stac.fun?token='
        this.pagePath = !this.mobileMonitor
          ? `${host}${res.data}&Mtype=0&env=${env}&is_interview=0`
          : `${host}${res.data}&Mtype=1&env=${env}&is_interview=0`
      }
    },
    async pushTestClick() {
      this.showPushTest = true
      this.showPhonePosition = false
      await this.pushTest()
    },
    // 获取推流测试结果
    async pushTest(flag = true) {
      setTimeout(async() => {
        const res = await Api.checkMobile()
        if (res.code === 0) {
          res.data
            ? this.pushSuccess()
            : flag
              ? this.pushTest(false)
              : this.pushFail()
        }
      }, 5000)
    },
    pushSuccess() {
      this.showPushTest = false
      this.showPushSuccess = true
    },
    pushFail() {
      this.showPushTest = false
      this.showPushFail = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/theme/variables.scss";
@import './index.scss';
</style>
